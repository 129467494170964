import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    VStack,
    HStack,
    Image,
    useToast
} from '@chakra-ui/react';
import useProductStore from '../../zustand/useProductStore';

const images = [
    { id: 'image1', src: 'images/Yes, there is a ladder to use.png', alt: 'Yes, there is a ladder to use', label: 'Yes, there is a ladder to use' },
    { id: 'image2', src: 'images/Yes, but there is no ladder.png', alt: 'Yes, but there is no ladder', label: 'Yes, but there is no ladder' },
    { id: 'image3', src: 'images/No loft hatch.png', alt: 'No loft hatch', label: 'No loft hatch' }
];

function CustomEPCModal({ isOpen, onClose, handleContinue }) {
    const { EPCOptions, setEPCOptions, setPropertyDetails, propertyDetails } = useProductStore();
    const toast = useToast()

    const handleImageClick = (imageLabel) => {
        setEPCOptions((prevExternalImages) => {
            let updated;

            // If "No loft hatch" is clicked, deselect all other options
            if (imageLabel === "No loft hatch") {
                if (prevExternalImages.includes("No loft hatch")) {
                    updated = prevExternalImages.filter((label) => label !== "No loft hatch");
                } else {
                    updated = ["No loft hatch"];
                }
            }
            // If any other image is clicked and "No loft hatch" is already selected, deselect "No loft hatch"
            else {
                if (prevExternalImages.includes(imageLabel)) {
                    updated = prevExternalImages.filter((label) => label !== imageLabel);
                } else {
                    updated = prevExternalImages.filter((label) => label !== "No loft hatch").concat(imageLabel);
                }
            }

            setPropertyDetails({
                ...propertyDetails,
                EPCOptions: updated
            });

            return updated;
        });
    };

    const handleContinueBtn = () => {
        if (EPCOptions.length === 0) {
            toast({
                title: "Please select atleast one option",
                variant: "left-accent",
                status: "error",
                duration: 2000,
                position: "bottom-right",
            });
        }
        else {
            handleContinue()
        }
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg="white" color="black.100">
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4} textAlign="center" mt={6}>
                        <Text fontSize="lg" fontWeight="bold">
                        Does the property have a loft hatch? 
                        </Text>
                        <VStack spacing={2} align="start">
                            {images.map(({ id, src, alt, label }) => (
                                <HStack
                                    key={id}
                                    onClick={() => handleImageClick(label)}
                                    cursor="pointer"
                                    border={EPCOptions.includes(label) ? "2px solid #BEEF8E" : "2px solid transparent"}
                                    px={3}
                                    py={1}
                                    borderRadius="full"
                                    >
                                    <Image
                                        src={src}
                                        alt={alt}
                                        boxSize="45px"
                                    // filter={EPCOptions.includes(label) ? 'invert(60%) sepia(40%) saturate(500%) hue-rotate(80deg)' : 'none'}
                                    />
                                    <Text>{label}</Text>
                                </HStack>
                            ))}
                        </VStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        mt={2}
                        mx="auto"
                        bgColor="green.1000"
                        onClick={() => handleContinueBtn()}
                        _hover={{ bg: 'green.1000', opacity: '0.7' }}
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default CustomEPCModal;
